import {BASE_URL_REDIRECT} from "@env";

function _encodeData(data) {
    return Object.keys(data).map(function(key) {
        return [key, data[key]].map(encodeURIComponent).join("=");
    }).join("&");
};

function createDeepLink (path, params) {
    return `${BASE_URL_REDIRECT}?page=${path}&${_encodeData(params)}`;
}

export default createDeepLink;